<script>
    import anime from "animejs"
    import { onMount } from 'svelte';
  
    onMount(()=> {
      let shapes = [
        {
     // id="m01"
      d:"M398,121c89.435-52.329,219-54,305,2s100,71,87,155s-11.062,107.471,20,194c42,117-353,236-492,123 c-123.804-100.646-154-274-64-326S304,176,398,121z"
    },
      {
  　　// id="m02"
          d: "M353,135c89.435-52.329,222,4,308,60s209,25,196,109s-41.062,115.471-10,202c42,117-465,246-604,133 c-123.804-100.646-20-263,70-315S259,190,353,135z"
      },
    {
  　　// id="m03"
      d:"M326,98c89.435-52.329,281-86,367-30s43,219,30,303s136.938,43.471,168,130c42,117-570,286-709,173 c-123.804-100.646-76-214,14-266S232,153,326,98z"
    }
  ]
  
  let morphbg = anime({
    targets: '.st1',
    d: [
          {value: [
            shapes[0].d,
            shapes[1].d
          ]},
          {value: shapes[2].d},
          {value: shapes[0].d}
      ],
      fill:['#FF0000',,'#dddddd','#8cdcda','#f16a70','#b1d877'],
      duration: 20000,
      autoplay: true,
      easing: 'cubicBezier(.5, .05, .1, .3)',
      loop: true
  });
    })
  
  </script>
  
  <svg version="1.1" id="morfingbg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
       y="0px" viewBox="0 0 1024 768" style="enable-background:new 0 0 1024 768;" xml:space="preserve">
  <!-- id="m01"のみHTMLに残す（m02とm03はjsコードで指定）。CSS用にクラス名を追加。 -->
  <path id="m01" class="st1" d="M398,121c89.435-52.329,219-54,305,2s100,71,87,155s-11.062,107.471,20,194c42,117-353,236-492,123 c-123.804-100.646-154-274-64-326S304,176,398,121z"/>
  </svg>
  
  
  <style>
  
    #morfingbg {
      position: fixed;
      top: -65vh;
      left: -100vw;
      width: 180%;
      height: 200%;
      overflow: hidden;
      transform: rotate(45deg);
    }
  
    .st1{
    fill:#FF0000;
  }
  </style>
  