<script>
  import Music from "./Music.svelte"
</script>

<div class="text-item">
  <h1 class="logo">
    <img src="/images/logo.svg"/>
  </h1>
  <Music />

</div>

<style lang="scss">

  .text-item {
    display: flex;
    width: 70%;
    position: relative;
    z-index: 9;
    flex-direction: column;
    @media screen and (max-width:768px) {
      width: 90vw;
    }
  }
  .logo {
    width: 60%;
    display: block;
    @media screen and (max-width:768px) {
      width: 70vw;
      margin: 0 auto;
      padding: 0;
      margin-bottom: 16vw;
    }
  }

  .logo img {
    width: 100%;
  }
  .text {
    width: 70%;
    @media screen and (max-width:768px) {
      width: 90%;
    }
  }

  .text img {
    width: 100%;
  }

  .title {
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 16px;
  }

  .lists {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    text-align: left;
    li {
      font-size: 10px;
      margin-bottom: 4px;
      line-height: 1.8;
      list-style: none;
      font-weight: bold;
      margin-right: 8px;
      &:after {
        content: "/";
        margin-left: 8px;
        color: #ff0000;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }
</style>