<script>
  import Countdown from "./components/Countdown.svelte";
  import Mophing from "./components/Mophing.svelte"
  import Bg from "./components/Bg.svelte"
  import Logo from "./components/Logo.svelte"
  import Contact from "./components/Contact.svelte"
  import { GoogleAnalytics } from '@beyonk/svelte-google-analytics'
</script>

<main>
  <GoogleAnalytics properties={['G-8069HXEN6N']} />
  <Bg />
  <Mophing/>
  <div class="clm">
    <Logo />
    <Contact />
  </div>
  <div class="clm">
    <Countdown />
  </div>
</main>

<style lang="scss">
  main {
    text-align: center;
    margin: 0 auto;
    display: flex;
    width: 100vw;
    @media screen and (max-width:768px) {
      flex-direction: column;
      height: auto;
      overflow: hidden;
      
    }

    .clm {
      width: 50vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      
      @media screen and (max-width:768px) {
        width:100vw;
        align-items: flex-start;
        height: auto;
      }      

      &:first-of-type {
        width: 70vw;
        align-items: flex-start;
        padding-left: 2.4rem;
        @media screen and (max-width:768px) {
          width: 100vw;
          padding-left: 0;
          padding: 1.2rem;
          min-height: 100vh;
        }
      }
      &:last-of-type {
        width: 30vw;

        @media screen and (max-width:768px) {
          width: 100vw;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          opacity: .3;
        }        
      }
    }
  }

  h1 {
    color: #ff3e00;
    text-transform: uppercase;
    font-size: 4em;
    font-weight: 100;
  }

  @media (min-width: 640px) {
    main {
      max-width: none;
    }
  }
</style>
