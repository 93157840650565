<div class="contact">
  <ul>
    <li>
      <a href="https://forms.gle/wLwqMvtqzNdjJkYV8" target="_blank">INFORMATION</a>
    </li>
  </ul>

</div>

<style lang="scss">
  .contact {
    margin-top: 30px;
    position: relative;
    z-index: 9;

    ul {
      margin: 0;
      padding: 0;
      text-align: left;
    }

    li {
      list-style: none;
      margin: 0 0 8px 0;
      line-height: 1;

      &:before {
        width: 6px;
        height: 2px;
        content: "";
        background: #000;
        position: relative;
        vertical-align: middle;
        display: inline-block;
        margin-right: 4px;
      }

    }

    a {
      display: inline-block;
      font-weight: bold;
      font-size: 12px;
      letter-spacing: .2em;
      border-bottom: 1px solid #000;
      text-decoration: none;
      color: #000;
      word-break: keep-all;      
      @media screen and (max-width:768px) {
        border-bottom: 0;
        text-decoration: underline;
      }
      &:hover {
        color: #fff;
        border-bottom: 1px solid #fff;
        text-decoration: none;
      }

      br {
        display: none;
        @media screen and (max-width:768px) {
          display: block;
        }
      }
    }
  }
</style>
