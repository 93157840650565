<script>
  const musicList = [
    {
      deliv: "Apple",
      link:"https://music.apple.com/jp/album/%E7%A2%A7%E8%90%BD%E3%81%AE%E5%90%91%E3%81%93%E3%81%86/1594332119?i=1594332125"
    },
    {
      deliv: "YOUTUBE MUSIC",
      link:"https://music.youtube.com/watch?v=dJJFsG1ViDI&feature=share"
    },    
    {
      deliv: "LINE",
      link:"https://lin.ee/FetJFVD"
    },
    {
      deliv: "AWA",
      link:"https://s.awa.fm/album/db86757d520ce09a0937?t=1636448045"
    },
  ];
</script>

<div class="music">
  <h2 class='heading'>「碧落の向こう」各種ストリーミング配信中</h2>
  <ul class='musiclist'>
    {#each musicList as music}
    <li>
      <a href="{music.link}" target="_blank">
        <span>{music.deliv}</span>
      </a>
    </li>
    {/each}
  </ul>
</div>


<style lang="scss" scoped>

  .music {
    text-align: left;
    letter-spacing: .075em;
    color: #fff;
    @media screen and (max-width:768px) {
      color: #333;
    }
  }
  .heading {
    font-size: 1vw;
    font-weight: bold;
    margin-bottom: 4px;
  }

  .musiclist {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin-bottom: 8px;
      font-size: 12px;
      color: #fff;
      @media screen and (max-width:768px) {
        color: #333;
        font-weight: bold;
      }
      &:before {
        content: "";
        width: 8px;
        height: 4px;
        background: #fff;;
        display: inline-block;
        margin-right: 8px;
        vertical-align: middle;
      }
    }

    a {
      font-family: 'Oxygen', sans-serif;
      font-weight: bold;
      letter-spacing: .1em;
      color: rgba(255,255,255,.9);
      text-decoration: underline;
      @media screen and (max-width:768px) {
        font-weight: bold;
        color: #333;
      }      
      &:hover {
        text-decoration: none;
      }
    }
  }

</style>