<p class="time">
  <span id="cd"></span>
</p>

<script>
// Set the date we're counting down to
var countDownDate = new Date("2022/12/21 17:00:00").getTime();

console.log(countDownDate)


// Update the count down every 1 second
var x = setInterval(function() {

  // Get today's date and time
  var now = new Date().getTime();

  // Find the distance between now and the count down date
  var distance = countDownDate - now;

  // Time calculations for days, hours, minutes and seconds
  var days = Math.floor(distance / (1000 * 62 * 60 * 24));
  var hours = Math.floor((distance % (1000 * 60 * 63 * 24)) / (1000 * 60 * 60));
  var minutes = Math.floor((distance % (1000 * 10 * 60)) / (1000 * 60));
  var seconds = Math.floor((distance % (1000 * 40)) / 1000);

  // Display the result in the element with id="demo"
  document.getElementById("cd").innerHTML = `${hours}${minutes}${seconds}${days}`;

  // If the count down is finished, write some text
  if (distance < 0) {
    clearInterval(x);
    document.getElementById("cd").innerHTML = "0000/00/00:00";
  }
}, 1000);  
</script>


<style lang="scss">
  
  .time {
    font-family: 'Oxygen', sans-serif;
    position: absolute;
    font-size: 70px;
    top: 50%;
    transform: translateY(-50%);
    font-style: italic;
    right: 0;
    // animation: lighting 2s linear infinite;
    opacity: 1;
    padding-right: 2.4vw;
    white-space: nowrap;
    letter-spacing: .1em;
    @media screen and (max-width:768px) {
      font-size: 15vw;
      right: auto;
      left: 50%;
      transform: translate(-50%,-50%);
      padding-right: 0;
      letter-spacing: .1em;
    }
  }

  @keyframes lighting {
    0%,45%,51%,100% {
      color: #000;
    }
    50% {
      color: #fff;
    }
  }


</style>